import { theme } from '@superhi/design'
import styled from 'styled-components'

export const Wrapper = styled.div`
  min-height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${theme.colors.PALE_BLUE_40};
`
