import React from 'react'
import { Lockup } from '@superhi/design'

import Page from '../../components/Page'

import { Wrapper } from './styles'

const NotFound = () => (
  <Page>
    <Wrapper>
      <Lockup title="404" subtitle="This page could not be found." />
    </Wrapper>
  </Page>
)

export default NotFound
